import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="logo">SaleScout</h1>
        <input type="checkbox" className="nav-toggle"></input>
        <nav className="nav-Menu">
          <ul className="nav-links">
            <li><a href="#">About</a></li>
            <li><a href="#">Pricing</a></li>
            <li><a href="#">Login</a></li>
            <li><a href="#">Sign up</a></li>
          </ul>
        </nav>
      </header>
    </div>
  );
}

export default App;
